import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  apiBase(): string {
    return environment.token_service_config.apiBase;
  }

  resumableUploadApiBase(): string {
    if (environment.fileApiBase) {
      return environment.fileApiBase;
    } else {
      return environment.token_service_config.apiBase;
    }
  }

  tusServer(): string {
    return this.resumableUploadApiBase();
  }

  userProfileApiBase(): string {
    return environment.upmsApiUrl;
  }

  milestoneApiBase(): string {
    if (environment.milestoneApiBase) {
      return environment.milestoneApiBase;
    } else {
      return environment.token_service_config.apiBase;
    }
  }

  signApiBase(): string {
    if (environment.signApiBase) {
      return environment.signApiBase;
    } else {
      return environment.token_service_config.apiBase;
    }
  }

  msgApiBase(): string {
    if (environment.msgApiBase) {
      return environment.msgApiBase;
    } else {
      return environment.token_service_config.apiBase;
    }
  }

  pvmsApiBase(): string {
    return environment.pvmsApiUrl;
  }

  collectoExportApi() {
    return environment.collectoSrvApi;
  }

  wtsApiBase() {
    return environment.wtsApiUrl;
  }

  kanbanApiBase() {
    return environment.kanbanApiUrl;
  }

  datevApiBase() {
    return environment.datevApiUrl;
  }

  datevClientId(): string {
    return environment.datevClientId;
  }

  isStagingEnv(): boolean {
    return environment.envName === 'staging';
  }
}
