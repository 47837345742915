<div class="mat-dialog-inner-wrap" [style.borderColor]="color" [class.mat-dialog-dynamic-width]="dynamicWidth">

  <h2 *ngIf="title" class="mat-dialog-header">
    {{ title | translate }}
  </h2>

  <div mat-dialog-content [class.p-0]="noPadding">
    <div class="d-flex justify-content-center mb-1" *ngIf="icon">
      <mat-icon aria-hidden="true" class="dialog-top-icon" [style.color]="color">{{icon}}</mat-icon>
    </div>

    <h3 [innerHTML]="body | translate"></h3>
  </div>

  <div class="mat-dialog-footer d-flex justify-content-end">
    <button mat-button mat-dialog-close="canceled" *ngIf="!disableCancelButton">{{cancelButtonTitle | translate}}</button>
    <button mat-raised-button (click)="data?.onSubmitAction()" [color]="confirmButtonColor" mat-dialog-close="ok" class="ml-2">{{ confirmButtonTitle | translate}}</button>
  </div>
</div>
