import * as model from './user.interface';

/// <reference path="./organization.interface.ts" />
export namespace Operator {
  export type UserAuthProviderType = 'credentials' | 'azure' | 'enforced_credentials';

  export class User implements model.Operator.IUser {
    readonly type = 'user_accounts';
    testAccount = false;
    lastSignInAt: Date;
    hasJap = false;
    hasKap = false;
    hasPc = false;
    hasSignature = false;
    hasMobile = false;
    hasOtp = false;
    confirmationLink?: string;

    total: number;
    perPage: number;
    records: number;

    creatorEmail: string;
    creatorName: string;
    invitationType: string;

    dmsSid: string;
    dmsUsername: string;
    dmsEmail: string;
    dmsHumanSize: string;
    dmsUsedInPercent: string;
    dmsDiskSpace: number;
    dmsFree: number;
    dmsDocumentCount: number;
    dmsFolderCount: number;

    trialDate: Date;

    authProvider: UserAuthProviderType = 'credentials';

    _roles = [];
    set roles(roles: string[]) {
      this._roles = roles || [];
      this.hasJap = this.hasRole('jap');
      this.hasKap = this.hasRole('kap');
      this.hasPc = this.hasRole('pc');
      this.hasSignature = this.hasRole('signature');
      this.hasMobile = this.hasRole('mobile');
    }

    get roles() {
      return this._roles;
    }

    constructor(public id: string,
                public email: string,
                public firstName: string,
                public lastName: string,
                public basicAccount,
                public createdAt: Date,
                public confirmedAt: Date,
                public lockedAt: Date = null) {}

    hasRole(role) {
      return !!this.roles.find(_role => _role === role);
    }
  }

  export class UserStats {
    readonly id: 'user_stats';
    readonly type: 'user_stats';

    constructor(public all: number,
                public memberOnly: number,
                public bpOnly: number,
                public cavOnly: number) {}
  }
}
