<div class="dvtx-orgaization-master-data-settings" *ngIf="(orga)">
  <h3>{{ 'ORGANIZATION.MASTER_DATA_TITLE' | translate}}</h3>
  <div class="row">
    <div class="col-12 col-sm-5 col-md-5 inline pl-2">
      <div class="w-100 row">
        <div class="col-12 col-md-7 inline pr-0">
            <mat-icon>domain</mat-icon>
          <!--<mat-form-field class="paddright10">-->
          <!--<input matInput placeholder="Name des Unternehmens" formControlName="organizationName" required>-->
          <!--</mat-form-field>-->
          <dvtx-text-input-cva
            class="w-100"
            [elementName]="'CONTACTS.ORG_NAME' | translate"
            [(ngModel)]="orga.name"
            [isDisabled]="readonly || !editmode"></dvtx-text-input-cva>
        </div>
        <div class="col-12 col-md-5 pl-4">
          <dvtx-api-select-cva
            class="w-100"
            [elementName]="'CONTACTS.LEGAL_FORM' | translate"
            listingType="legal-form"
            listingPath="name"
            [isDisabled]="readonly || !editmode"
            [(ngModel)]="orga.legalFormId">
          </dvtx-api-select-cva>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <h3>{{ 'ORGANIZATION.CONTACT_EMAIL' | translate }}</h3>
  <div class="row">
    <div class="m-0 col-12 col-sm-5 col-md-4 inline pl-2">
        <mat-icon>email</mat-icon>
      <dvtx-email-address-input
        [(ngModel)]="orga.email"
        [isDisabled]="readonly || !editmode"
      >
      </dvtx-email-address-input>
    </div>
  </div>

  <h3>{{ 'ORGANIZATION.PHONE_TITLE' | translate }}</h3>
  <div class="row">
    <div class="m-0 col-12 col-sm-5 col-md-4 inline pl-2">
      <mat-icon>phone</mat-icon>
      <dvtx-telephone-input
        [(ngModel)]="orga.telephone"
        [isDisabled]="readonly || !editmode"
        [isType]="true"
      >
      </dvtx-telephone-input>
    </div>
  </div>

  <hr>
  <h3>{{ 'ORGANIZATION.ADDRESS_TITLE' | translate }}</h3>
  <div class="row">
    <div class="m-0 col-12 col-sm-5 col-md-4 inline pl-2">
      <mat-icon>place</mat-icon>
      <dvtx-address-input-cva
        [enableLocationOrType]="false"
        [elementName]="'ORGANIZATION.ADDRESS_BOOK' | translate"
        [isDisabled]="readonly || !editmode"
        [(ngModel)]="orga.address"></dvtx-address-input-cva>
    </div>
  </div>

  <hr>
  <h3>{{ 'CLIENT.DATEV_CONSULTANT_TITLE' | translate }}</h3>
  <div class="row">
    <div class="m-0 col-12 col-sm-5 col-md-4 inline pl-2"
         [formGroup]="organizationProxyForm">
      <mat-icon svgIcon="datev"></mat-icon>
      <dvtx-text-input-cva
        [elementName]="'CLIENT.DATEV_CONSULTANT_ID' | translate"
        formControlName="consultantId"
        [isDisabled]="readonly || !editmode"></dvtx-text-input-cva>
    </div>
  </div>
</div>
