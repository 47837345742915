
import {first} from 'rxjs/operators';
import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {NotificationService} from '../../../../shared/modules/notification/services/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {IProcessContext} from '../../../../shared/modules/api/models/process-context.interface';
import {Process} from '../../../../shared/modules/api/models/process';
import {AngularTokenService} from 'angular-token';
import {FileUtils} from 'app/lib/file_utils';
import {environment} from 'environments/environment';
import {UploadUtils} from 'app/lib/upload_utils';
import {ProcessActions} from 'app/+store/process';
import { QuickstartService } from 'app/+store/process/quickstart.service';
import {TranslateService} from '@ngx-translate/core';
import {ProcessArtifactActions} from "../../../../+store/process-artifact";
import {ProcessEventActions} from "../../../../+store/process-event";

@Component({
  selector: 'dvtx-quick-upload-dialog-container',
  templateUrl: './quick-upload-dialog.component.html',
  styleUrls: ['./quick-upload-dialog.component.scss']
})
export class QuickUploadDialogContainerComponent {
  SUPPORTED_FILE_TYPES: string[] = FileUtils.DEFAULT_SUPPORTED_FILE_TYPES;
  MAX_FILE_SIZE = FileUtils.DEFAULT_MAX_FILE_SIZE;

  url: string;

  form: UntypedFormGroup;
  uploader: FileUploader;
  uploadedFiles: FileItem[] = [];
  uploadSuccess = false;
  dmsPathChooser;
  processId;

  constructor(private _dialogRef: MatDialogRef<QuickUploadDialogContainerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _fb: UntypedFormBuilder,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService,
              private _store: Store<AppState>,
              private _quickstartSvc: QuickstartService,
              private _tokenSvc: AngularTokenService) {
    this.processId = this.data.options.processId;
    this.url = '';
    this.dmsPathChooser = this.data.options.dmsPathChooser;
    const config = {
      url: '',
      dms_folder_id: this.dmsPathChooser ? '' : '0000-0000-0000-0000', // this.data.configuration.dms_folder_id
      dms_path: this.dmsPathChooser ? '' : 'invalid',
      dms_account_type: []
    };
    this._initUploadWorker(config);
    this._initForm(config);
  }

  addFile(fileContext) {
    if (this._validateFile(fileContext)) {
      return;
    }

    const control = <UntypedFormArray>this.form.controls['files'];
    control.push(this._fb.group({
      title: [fileContext.file.name],
      type: [''],
      tag: [''],
      tags: this._fb.array([])
    }));
    this.form.markAsDirty();
    return fileContext;
  }

  removeFile(i: number) {
    const control = <UntypedFormArray>this.form.controls['files'];
    control.removeAt(i);
  }

  getFileValue(i: number) {
    const control = <UntypedFormArray>this.form.controls['files'];
    return control.at(i).value;
  }

  submit() {
    if (this.processId) { // Process present to upload to.
      this._uploadToProcess(this.processId);

    } else { // Create a new process.
      const formValue = this.form.value;
      const process = new Process(null, 'quickstart', '0.1.0', 'Upload zum DMS',
        formValue.subtitle, formValue.description, formValue.due_date, formValue.dms_folder_id);
      process.dms_account_type = formValue.dms_account_type;
      this._quickstartSvc.create(process).subscribe((processContext: IProcessContext) => {
        this._uploadToProcess(processContext.id);

        // processContext.runCommands('on_create');
        this._store.dispatch(new ProcessActions.RunCommand(processContext.id, 'create', 'on_create'));
      });
    }
  }

  _uploadToProcess = (id) => {
    this.url = this._apiUploadUrl(id);
    this.uploader.options.url = this.url;
    this._notifyService.info('DMS.DOCUMENTS_UPLOADED_TO_DMS')
    this.uploader.uploadAll();
  }

  getInvalidFiles() {
    const files = this.uploader.queue;
    const invalidFiles = [];
    files.forEach(fileItem => {
      const invalidFile = this._validateFile(fileItem);
      if (invalidFile) {
        invalidFiles.push(invalidFile);
      }
    });
    return invalidFiles;
  }

  private _validateFile(fileItem) {
    const extension = FileUtils.getExtension(fileItem.file.name);

    if (this.SUPPORTED_FILE_TYPES.indexOf(extension) === -1) {
      return {
        name: fileItem.file.name,
        reason: 'Dateiformat wird nicht unterstützt: ' + extension
      };
    }

    if (fileItem.file.size / 1024 / 1024 > this.MAX_FILE_SIZE) {
      return {
        name: fileItem.file.name,
        reason: `Datei größer als ${ this.MAX_FILE_SIZE} MB`
      };
    }
    return null;
  }

  private _initUploadWorker(config) {
    const requestAuthHeaders = UploadUtils.authHeaders(this._tokenSvc.currentAuthData);
    this.uploader = new FileUploader(
      {
        url: config.url,
        headers: requestAuthHeaders,
        autoUpload: false,
        itemAlias: 'attachment'
      });
    this.uploader.onBeforeUploadItem = (item => item.url = this.url);
    this.uploader.onAfterAddingFile = (fileContext) => {
      fileContext.withCredentials = false;
      this.addFile(fileContext);
    };
    this.uploader.onBuildItemForm = (item, form) => {
      item.url = this.url;

      const index = this.uploader.queue.indexOf(item);
      const formObject = this.getFileValue(index);
      for (const key in formObject) {
        if (formObject.hasOwnProperty(key)) {
          form.append(key, formObject[key]);
        }
      }
      form.append('dms_folder_id', this.form.get('dms_folder_id').value);
      form.append('dms_path', this.form.get('dms_path').value);
      form.append('externally_available', this.form.get('externally_available').value);
      form.append('documents_externally_available_until',
        this.form.get('documents_externally_available_until').value);
      form.append('password', this.form.get('password').value);
      // form.append('tags', JSON.stringify(form.get('tags').value));
    };
    this.uploader.onCancelItem = (item: FileItem, response: string, status: number, headers: any) => {
      const index = this.uploader.queue.indexOf(item);
      this.removeFile(index);
    };

    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: any) => {
      const index = this.uploader.queue.indexOf(item);
      this.uploader.removeFromQueue(item);
      this.removeFile(index);
      this._notifyService.success('DMS.DOCUMENT_SUCCESSFULLY_UPLOADED_PR_UPDATED', {file: item.file.name})
    };

    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: any) => {
      if (status === 422 || status === 400) {
        this._notifyService.error('UPLOAD.UPLOAD_FAILURE_SERVER_ISSUE')
      } else {
        this._notifyService.error('UPLOAD.UPLOAD_FAILURE_FILE_ISSUE')
      }
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
        this.uploadedFiles.push(item);
        if (this.processId) {
          this._store.dispatch(new ProcessArtifactActions.LoadAll(this.processId));
          this._store.dispatch(new ProcessEventActions.LoadAll(this.processId, 1));
        }
      } else if (status === 422 || status === 400) {
        this._notifyService.error('UPLOAD.UPLOAD_FAILURE_SERVER_ISSUE')
      } else {
        this._notifyService.error('UPLOAD.UPLOAD_FAILURE_FILE_ISSUE')
      }
    };

    this.uploader.onCompleteAll = () => {
      if (this.uploader.queue.length === 0) {
        this.uploadSuccess = true;
        if (this.processId) {
          this._store.dispatch(new ProcessArtifactActions.LoadAll(this.processId));
          this._store.dispatch(new ProcessEventActions.LoadAll(this.processId, 1));
        }
        this._dialogRef.close();
      }
    };

    // Hack for dialog closing on backdrop click
    document.getElementsByClassName('cdk-overlay-backdrop')[0].addEventListener('click', () => {
      this._dialogRef.close(this.uploadedFiles);
    });
  }

  private _initForm(config) {
    this.form = this._fb.group({
      files: this._fb.array([], Validators.required),
      externally_available: [false],
      documents_externally_available_until: [null],
      password: [null],
      dms_folder_id: [config.dms_folder_id || '', Validators.required],
      dms_account_type: [config.dms_account_type || ''],
      dms_path: [config.dms_path || '']
    });
  }

  private _apiUploadUrl(id) {
    const apiBase = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}`;
    return `${apiBase}/workflow_engine/quickstart/${id}/upload`;
  }
}
