<div class="row">
  <div class="col-12 col-md-8" [class.col-md-12]="isFullWidth">
    <dvtx-int-telephone-input
      name="phone"
      [(ngModel)]="innerValue"
      [enablePlaceholder]="true"
      [enableAutoCountrySelect]="true"
      [isDisabled]="isDisabled"
      [preferredCountries]="['de']"
      [options]="options"
      (autoCompleteFun)="autoCompleteFunction($event)"
      [phoneType]="innerValue.locationOrType">
    </dvtx-int-telephone-input>
  </div>

  <div class="col-12 col-md-4" *ngIf="isType">
    <dvtx-api-select-cva
      class="type-or-location"
      [elementName]="'ORGANIZATION.CREATION.TYPE' | translate"
      listingPath="name"
      listingType="typed_location-or-phone-type"
      [(ngModel)]="innerValue.locationOrType"
      [isDisabled]="isDisabled">
    </dvtx-api-select-cva>
  </div>
</div>
